import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'
import imagePlaceholder from '../../../../static/product-image-placeholder.png'

export const StyledContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: ${spacing(3)};
  position: relative;

  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media ${device.tabletLand} {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media ${device.desktop} {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    margin-top: ${spacing(4)};
    padding-left: 12px;
    padding-right: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    transition: transform 300ms;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.08);
        z-index: 3;

        .card-extra {
          display: flex;
        }
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      z-index: 2;

      .link {
        .sale-badge {
          background-color: #f53623;
          color: #fff;
          font-size: 13px;
          line-height: 13px;
          font-weight: 700;
          text-transform: uppercase;
          padding: ${spacing(1 / 2)};
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .image {
          min-height: 190px;
        }

        .image-placeholder {
          background-image: url(${imagePlaceholder});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-color: rgb(241, 241, 241);
          height: 220px;
          width: 100%;
        }

        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          margin: ${spacing(1, 0, 0, 0)};
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre-wrap;
          padding: ${spacing(0, 1)};
        }

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: ${spacing(1)};
          padding: ${spacing(0, 1)};

          .col {
            flex: 0 0 auto;
            max-width: 50%;
          }
        }

        .attribute {
          font-size: 14px;
          line-height: 20px;
          opacity: 0.8;
          margin: 0;

          &.line-through {
            text-decoration: line-through;
          }
        }
      }
    }

    .card-extra {
      background-color: #fff;
      display: none;
      flex-direction: column;
      box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.152146);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: calc(100% + ${spacing(4)} + 40px);
      padding-bottom: ${spacing(2)};

      .link-more {
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${spacing(1)};
        background-color: #0074b8;
        border-radius: 2;
        color: #fff;
        margin-top: auto;
        margin-left: ${spacing(1)};
        margin-right: ${spacing(1)};
        height: 40px;
        transition: opacity 300ms;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`
