import styled from 'styled-components'
import Aside from '../../components/aside'
import CallbackBlock from '../../components/aside/callback-block'
import { spacing, device } from '../../utils/mixins'

export const StyledInfoAside = styled.aside`
  flex: 0 0 100%;
  margin-top: ${spacing(2)};

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }

  @media ${device.tabletLand}, ${device.desktop} {
    flex: 0 0 calc(30% - ${spacing(2)});
    max-width: calc(30% - ${spacing(2)});
    margin-top: 0;
  }
`

export const StyledCallbackBlock = styled(CallbackBlock)`
  border-top: none;
  padding-top: 0;

  button {
    height: 60px;
    max-width: 100%;

    @media ${device.tabletLand}, ${device.desktop} {
      max-width: 220px;
    }
  }

  @media ${device.tablet} {
    flex: 0 0 calc(50% - ${spacing(2)});
    max-width: calc(50% - ${spacing(2)});
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
  }
`

export const StyledAside = styled(Aside)`
  margin-top: ${spacing(4)};

  @media ${device.tablet}, ${device.tabletLand} {
    display: none;
  }
`
