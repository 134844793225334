import styled from 'styled-components'
import { spacing } from '../../../utils/mixins'

export const StyledTitle = styled.h5`
  border-bottom: 4px solid #9a6244;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  display: inline-block;
  text-transform: uppercase;
  margin: ${spacing(0, 0, 2, 0)};
  padding-bottom: ${spacing(3 / 2)};
`
