import styled from 'styled-components'
import { spacing } from '../../../utils/mixins'

export const StyledContainer = styled.div`
  margin-bottom: ${spacing(4)};
  .title {
    font: 400 18px/40px Tenor, sans-serif;
    margin: 0;
  }

  .parameters-list {
    border-top: 2px solid #f0f0f0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .parameters-list-item {
      font-size: 14px;
      line-height: 14px;
      flex: 0 0 50%;
      max-width: 50%;
      padding: ${spacing(1, 0)};

      &:nth-of-type(even) {
        border-left: 2px solid #f0f0f0;
        text-align: right;
      }
    }
  }
`
