import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SwiperCore, { EffectFade } from 'swiper'
import { Swiper } from 'swiper/react'
import { StyledContainer } from './styledComponents'

SwiperCore.use([EffectFade])

const settings = {
  effect: 'fade',
  autoHeight: true,
  allowTouchMove: false,
}

const ProductTabsContent = ({ children, index }) => {
  const swiperRef = useRef()

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index)
    }
  }, [swiperRef, index])

  return (
    <StyledContainer>
      <div className='mobile-content'>{children}</div>

      <Swiper {...settings} ref={swiperRef}>
        {children}
      </Swiper>
    </StyledContainer>
  )
}

ProductTabsContent.propTypes = {
  index: PropTypes.number.isRequired,
}

export default memo(ProductTabsContent)
