import React from 'react'
import { StyledLinkBack } from './styledComponents'

const LinkBack = ({ children, ...rest }) => {
  return (
    <StyledLinkBack {...rest}>
      <svg viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M8 15L1 7.98258L8 1' stroke='#9A6244' />
      </svg>
      {children}
    </StyledLinkBack>
  )
}

export default LinkBack
