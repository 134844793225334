import styled from 'styled-components'
import { dotsBaseStyles } from '../../../components/carousel/styledComponents'
import ProductCard from '../../../components/catalog/product-card'
import Section from '../section'

export const StyledSection = styled(Section)`
  ${dotsBaseStyles}
`

export const StyledProductCard = styled(ProductCard)`
  flex: 0 0 100% !important;
  max-width: 100% !important;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;

  .card-wrapper {
    @media (hover: hover) {
      &:hover {
        transform: scale(1);

        .card-extra {
          display: none;
        }
      }
    }
  }
`
