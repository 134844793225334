import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { StyledContainer } from './styledComponents'
import numberWithSpaces from '../../../utils/numberWithSpaces'

const ProductCard = ({
  image,
  name,
  slug,
  square,
  price,
  salePrice,
  ...rest
}) => {
  const link = `/catalog/${slug}`

  return (
    <StyledContainer {...rest}>
      <div className='card-wrapper'>
        <div className='card'>
          <Link className='link' to={link}>
            {salePrice && <div className='sale-badge'>% скидка</div>}

            {image ? (
              <Img
                className='image'
                fluid={image.mediaFile.childImageSharp.fluid}
              />
            ) : (
              <div className='image-placeholder' />
            )}

            <h3 className='title'>{name}</h3>

            <div className='row'>
              <div className='col'>
                <p className='attribute'>
                  Дом {square} м<sup>2</sup>
                </p>
              </div>
              <div className='col'>
                <p className='attribute'>от {numberWithSpaces(price)} ₽</p>
              </div>
            </div>

            {salePrice && (
              <div className='row'>
                <div className='col' />
                <div className='col'>
                  <p className='attribute line-through'>
                    {numberWithSpaces(salePrice)}
                  </p>
                </div>
              </div>
            )}
          </Link>
        </div>

        <div className='card-extra'>
          <Link className='link-more' to={link}>
            Подробнее
          </Link>
        </div>
      </div>
    </StyledContainer>
  )
}

ProductCard.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  slug: PropTypes.string,
  square: PropTypes.number,
  price: PropTypes.number,
}

export default ProductCard
