import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styledComponents'

const ProductInfo = ({
  square,
  width,
  height,
  floors,
  bathrooms,
  rooms,
  price,
}) => {
  return (
    <StyledContainer>
      <div className='block column'>
        <div className='item-row'>
          <span>Общая площадь</span>
          <span>
            {square} м<sup>2</sup>
          </span>
        </div>
        <div className='item-row'>
          <span>Размеры участка</span>
          <span>
            {width} <small>&#10539;</small> {height} м<sup>2</sup>
          </span>
        </div>
      </div>

      <div className='block row'>
        <div className='item-column'>
          <span>Этажей</span>
          <span>
            <strong>{floors}</strong>
          </span>
        </div>
        <div className='item-column'>
          <span>Сан узлов</span>
          <span>
            <strong>{bathrooms}</strong>
          </span>
        </div>
        <div className='item-column'>
          <span>Комнат</span>
          <span>
            <strong>{rooms}</strong>
          </span>
        </div>
      </div>

      <div className='block'>
        <div className='price'>
          <sup className='sup-1'>от</sup>{' '}
          <span className='price-value'>{price}</span>
          <sup className='sup-2'>
            <strong>₽</strong>
          </sup>
        </div>
      </div>
    </StyledContainer>
  )
}

ProductInfo.propTypes = {
  square: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  floors: PropTypes.number,
  bathrooms: PropTypes.number,
  rooms: PropTypes.number,
  price: PropTypes.string,
}

export default ProductInfo
