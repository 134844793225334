import { Link } from 'gatsby'
import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'

export const StyledLinkBack = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #a46e4f;
  text-decoration: none;
  transition: opacity 300ms;
  margin: 25px 0 10px;

  @media ${device.tablet} {
    margin: 28px 0 10px;
  }

  @media ${device.tabletLand} {
    margin: 40px 0 30px;
  }

  @media ${device.desktop} {
    margin: 33px 0 8px;
  }

  &:hover {
    opacity: 0.6;
  }

  svg {
    margin-right: ${spacing(2)};
    width: 7px;
  }
`
