import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledContainer = styled.div`
  .presets-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    font-size: 14px;
    line-height: 20px;

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + .list-item {
        margin-top: ${spacing(1)};
      }
    }

    @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
      display: none;
    }
  }

  .presets-table {
    display: none;

    @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
      display: flex;
      flex-direction: column;
    }

    .col {
      flex: 0 0 calc(${70 / 4}%);
      max-width: calc(${70 / 4}%);

      &:first-child {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }

    .head {
      display: flex;
      padding: ${spacing(1, 0)};
      width: 100%;

      .col {
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .name {
            font-size: 11px;
            line-height: 15px;
            text-transform: uppercase;
          }

          .price {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .body {
      width: 100%;

      .row {
        border-top: 2px solid #f0f0f0;
        padding: ${spacing(1, 0)};
        display: flex;

        .col {
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            justify-content: flex-start;
          }

          .content {
            font-size: 14px;
            line-height: 20px;

            .circle {
              background-color: #d8d8d8;
              border-radius: 50%;
              display: block;
              height: 10px;
              width: 10px;
            }
          }
        }
      }
    }
  }
`
