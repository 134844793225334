import React from 'react'
import PropTypes from 'prop-types'
import SwiperCore, { Pagination } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'
import { size } from '../../../utils/mixins'
import { StyledProductCard, StyledSection } from './styledComponents'
import Container from '../../../components/container'

SwiperCore.use([Pagination])

const settings = {
  slidesPerView: 1,
  spaceBetween: 15,
  watchOverflow: true,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    [size.phone]: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    [size.tablet]: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    [size.tabletLand]: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
}

const RelatedProducts = ({ items }) => {
  return (
    <Container>
      <StyledSection title='Похожие продукты'>
        <Swiper {...settings}>
          {items.map(
            ({ id, name, images, minPrice, minSalePrice, square, slug }) => (
              <SwiperSlide key={id}>
                <StyledProductCard
                  image={images[0]}
                  name={name}
                  slug={slug}
                  square={square}
                  price={minPrice}
                  salePrice={minSalePrice}
                />
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </StyledSection>
    </Container>
  )
}

RelatedProducts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export default RelatedProducts
