import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  StyledContainer,
  StyledImagePlaceholder,
  StyledSliderContainer,
  StyledSlideshowContainer,
} from './styledComponents'
import { useTransition, animated, config } from 'react-spring'

SwiperCore.use([Pagination, Navigation])

const swiperSliderSettings = {
  slidesPerView: 1,
  watchOverflow: true,
  pagination: {
    clickable: true,
  },
}

const swiperSlideshowSettings = {
  slidesPerView: 8,
  spaceBetween: 8,
}

const ProductImages = ({ images }) => {
  const [activeImage, setActiveImage] = useState(images[0])
  const transitions = useTransition(activeImage, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  if (!images?.length) {
    return <StyledImagePlaceholder />
  }

  return (
    <StyledContainer>
      <StyledSliderContainer>
        <Swiper {...swiperSliderSettings}>
          {images.map(image => (
            <SwiperSlide key={image.url}>
              <Img fluid={image.mediaFile.childImageSharp.fluid} />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSliderContainer>

      <StyledSlideshowContainer>
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props}>
            <Img fluid={item.mediaFile.childImageSharp.fluid} />
          </animated.div>
        ))}

        {images.length > 1 && (
          <Swiper {...swiperSlideshowSettings}>
            {images.map(image => (
              <SwiperSlide key={image.url}>
                <div
                  className={
                    activeImage.id === image.id
                      ? 'image-container active'
                      : 'image-container'
                  }
                  onClick={() => setActiveImage(image)}
                >
                  <Img fluid={image.mediaFile.childImageSharp.fluid} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </StyledSlideshowContainer>
    </StyledContainer>
  )
}

ProductImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
}

export default ProductImages
