import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledContainer = styled.div`
  border: 4px solid #f0f0f0;
  padding: ${spacing(3)};

  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .block {
    display: flex;
    font-size: 14px;
    line-height: 20px;

    &.column {
      flex-direction: column;
    }

    &.row {
      flex-direction: row;
      justify-content: space-between;
    }

    & + .block {
      border-top: 2px solid #f0f0f0;
      margin-top: ${spacing(2)};
      padding-top: ${spacing(2)};
    }

    .item-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + .item-row {
        margin-top: ${spacing(1)};
      }

      small {
        font-size: 8px;
        position: relative;
        bottom: 2px;
      }
    }

    .item-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      strong {
        font: 400 24px/30px Tenor, sans-serif;
        margin-top: ${spacing(1)};
      }
    }

    .price {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 100%;
      max-width: 100%;

      .sup-1 {
        font-size: 14px;
        line-height: 14px;
      }

      .price-value {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin: ${spacing(0, 1)};
      }

      .sup-2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
`
