import styled from 'styled-components'
import { device } from '../../../utils/mixins'

export const StyledContainer = styled.div`
  figure {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-content {
    @media ${device.desktop} {
      display: none;
    }
  }

  .swiper-container {
    display: none;

    @media ${device.desktop} {
      display: block;
    }
  }
`
