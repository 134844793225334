import styled from 'styled-components'
import { spacing } from '../../../utils/mixins'

export const StyledSection = styled.section`
  background-color: #fff;
  flex: 0 0 100%;
  max-width: 100%;
  margin: ${spacing(2, 0)};

  .title {
    margin: ${spacing(0, 0, 2)};
  }
`
