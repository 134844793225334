import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styledComponents'

const ProductAsideParameters = ({ name, square, pricePerSquare }) => {
  return (
    <StyledContainer>
      <h5 className='title'>{name}</h5>
      <ul className='parameters-list'>
        <li className='parameters-list-item'>
          Дом {square} м<sup>2</sup>
        </li>
        <li className='parameters-list-item'>
          от {pricePerSquare} ₽/м<sup>2</sup>
        </li>
      </ul>
    </StyledContainer>
  )
}

ProductAsideParameters.propTypes = {
  name: PropTypes.string.isRequired,
  square: PropTypes.number.isRequired,
  pricePerSquare: PropTypes.number.isRequired,
}

export default ProductAsideParameters
