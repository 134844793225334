import React from 'react'
import { StyledSection } from './styledComponents'

const Section = ({ children, title, ...rest }) => {
  return (
    <StyledSection {...rest}>
      <h3 className='title'>{title}</h3>
      {children}
    </StyledSection>
  )
}

export default Section
