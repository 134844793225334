import { graphql } from 'gatsby'
import React, { useMemo, useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkBack from '../../components/link-back'
import ProductImages from './product-images'
import ProductTabsContent from './product-tabs-content'
import {
  StyledInfoAside,
  StyledCallbackBlock,
  StyledAside,
} from './styledComponents'
import ProductInfo from './product-info'
import ProductPresets from './product-presets'
import { ImageFigure, PageTitle } from '../../components/blog'
import {
  StyledArticle,
  StyledContainer,
} from '../../components/blog/styledComponents'
import AsideTitle from '../../components/aside/title'
import { AsideLink, AsideLinks } from '../../components/aside/links-list'
import ProductAsideParameters from './product-aside-parameters'
import numberWithSpaces from '../../utils/numberWithSpaces'
import RelatedProducts from './related-products'
import Section from './section'

export const query = graphql`
  query ProductQuery($id: ID!) {
    strapiGraphql {
      product(id: $id) {
        pageSeo {
          description
          keywords
          title
        }
        id
        slug
        name
        minPrice
        bathrooms
        height
        rooms
        square
        width
        projectFloor {
          value
        }
        presets {
          id
          price
          projectPreset {
            id
            name
            presetFeatures {
              id
            }
          }
        }
        images {
          url
          id
          mediaFile {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        layout {
          url
          id
          caption
          alternativeText
          mediaFile {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        facade {
          url
          id
          caption
          alternativeText
          mediaFile {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        relatedProducts {
          id
          name
          minPrice
          saleMinPrice
          slug
          square
          images {
            url
            mediaFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 220, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }

      projectPresets {
        id
        name
        presetFeatures {
          id
          name
        }
      }
      presetFeatures {
        id
        name
      }
    }
  }
`

export default function Product({ data }) {
  const {
    pageSeo,
    name,
    minPrice,
    images,
    square,
    width,
    height,
    projectFloor,
    bathrooms,
    rooms,
    facade,
    layout,
    presets,
    relatedProducts,
  } = data.strapiGraphql.product
  const pricePerSquare = Math.round(minPrice / square)

  const {
    projectPresets: allPresets,
    presetFeatures: allFeatures,
  } = data.strapiGraphql

  // don't know why but strapi graphql returns error if try to get
  // product preset features names, id is good, but with name error occurs
  // thats why I decided to get all features names of presets from it's collection directly
  // and give it to component in one place
  const validPresets = useMemo(
    () =>
      presets.map(item => ({
        ...item,
        projectPreset: allPresets.find(
          ({ id }) => id === item.projectPreset.id,
        ),
      })),
    [presets, allPresets],
  )

  const tabsContent = useMemo(
    () => [
      {
        title: 'Фасад',
        images: facade,
      },
      {
        title: 'Планировка',
        images: layout,
      },
      {
        title: 'Комплектация и цены',
        presets,
      },
    ],
    [facade, layout, presets],
  )
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <Layout>
      <SEO {...pageSeo} />
      <StyledContainer>
        <LinkBack to='/catalog'>Все проекты</LinkBack>

        <PageTitle>{name}</PageTitle>

        <ProductImages images={images} />

        <StyledInfoAside>
          <ProductInfo
            square={square}
            width={width}
            height={height}
            floors={projectFloor.value}
            bathrooms={bathrooms}
            rooms={rooms}
            price={numberWithSpaces(minPrice)}
          />
          <StyledCallbackBlock buttonText='Получить консультацию' />
        </StyledInfoAside>

        <StyledArticle>
          <ProductTabsContent index={activeTabIndex}>
            {tabsContent.map(({ title, images, presets }) => {
              if (images?.length) {
                return (
                  <SwiperSlide key={title}>
                    <Section title={title}>
                      {images.map(
                        ({ mediaFile, id, alternativeText, caption }) => (
                          <ImageFigure
                            key={id}
                            alt={alternativeText}
                            caption={caption}
                            fluid={mediaFile.childImageSharp.fluid}
                          />
                        ),
                      )}
                    </Section>
                  </SwiperSlide>
                )
              } else if (presets?.length) {
                return (
                  <SwiperSlide key={title}>
                    <Section title={title}>
                      <ProductPresets
                        items={validPresets}
                        allFeatures={allFeatures}
                      />
                    </Section>
                  </SwiperSlide>
                )
              }
            })}
          </ProductTabsContent>
        </StyledArticle>

        <StyledAside>
          <AsideTitle>ПРОЕКТ</AsideTitle>
          <ProductAsideParameters
            name={name}
            square={square}
            pricePerSquare={pricePerSquare}
          />
          <AsideLinks>
            {tabsContent.map((tab, index) => (
              <AsideLink
                isActive={activeTabIndex === index}
                onClick={() => setActiveTabIndex(index)}
                key={tab.title}
              >
                {tab.title}
              </AsideLink>
            ))}
          </AsideLinks>
        </StyledAside>
      </StyledContainer>

      {Boolean(relatedProducts.length) && (
        <RelatedProducts items={relatedProducts} />
      )}
    </Layout>
  )
}
