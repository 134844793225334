import styled from 'styled-components'
import imagePlaceholder from '../../../../static/product-image-placeholder.png'
import { spacing, device } from '../../../utils/mixins'
import { dotsBaseStyles } from '../../../components/carousel/styledComponents'

export const StyledContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  @media ${device.tabletLand}, ${device.desktop} {
    flex: 0 0 calc(70% - ${spacing(2)});
    max-width: calc(70% - ${spacing(2)});
  }
`

export const StyledSliderContainer = styled.div`
  ${dotsBaseStyles}
  .swiper-container {
    @media ${device.tabletLand}, ${device.desktop} {
      display: none;
    }
  }
`

export const StyledSlideshowContainer = styled.div`
  display: none;

  @media ${device.tabletLand}, ${device.desktop} {
    display: block;

    .image-container {
      cursor: pointer;
      margin-top: ${spacing(1)};
      transition: opacity 300ms;

      img {
        height: 70px;
        width: 70px;
      }

      &.active {
        opacity: 0.5;
        cursor: default;
        &:hover {
          opacity: 0.5;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
`

export const StyledImagePlaceholder = styled.div`
  background-image: url(${imagePlaceholder});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: rgb(241, 241, 241);
  height: 220px;
  width: 100%;

  @media ${device.tablet} {
    height: 420px;
  }

  @media ${device.tabletLand}, ${device.desktop} {
    height: 500px;
  }
`
