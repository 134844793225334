import styled, { css } from 'styled-components'
import { spacing } from '../../../utils/mixins'

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

export const StyledListItem = styled.li`
  margin-top: ${spacing(2)};
`

const linkStyles = css`
  font: 400 18px/18px Tenor, sans-serif;
  color: #000000;
  cursor: pointer;
  position: relative;
  transition: opacity 300ms;

  &::before {
    background-color: #9a6244;
    border-radius: 50%;
    content: '';
    position: absolute;
    right: calc(100% + ${spacing(1)});
    top: 5px;
    margin: auto;
    height: 10px;
    width: 10px;
    opacity: 0;
  }

  &:hover {
    opacity: 0.6;
  }

  ${props =>
    props.isactive && {
      color: '#9a6244',

      '&::before': {
        opacity: 1,
      },

      '&:hover': {
        opacity: 1,
      },
    }}
`

export const StyledLink = styled.a`
  text-decoration: none;
  ${linkStyles}
`

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  ${linkStyles}
`
