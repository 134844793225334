import { spacing } from '../../utils/mixins'
import { css } from 'styled-components'

const dotSize = '8px'
export const dotsBaseStyles = css`
  .swiper-container {
    padding-bottom: ${spacing(4)};

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        background-color: transparent;
        border: 1px solid #111111;
        height: ${dotSize};
        width: ${dotSize};

        &:hover {
          opacity: 0.5;
        }
      }

      .swiper-pagination-bullet-active {
        cursor: default;

        background-color: #9a6244;
        border-color: #9a6244;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`
