import React, { memo } from 'react'
import PropTypes from 'prop-types'
import numberWithSpaces from '../../../utils/numberWithSpaces'
import { StyledContainer } from './styledComponents'

const ProductPresets = ({ items, allFeatures }) => {
  return (
    <StyledContainer>
      <ul className='presets-list'>
        {items.map(({ id, price, projectPreset }) => (
          <li key={id} className='list-item'>
            {projectPreset.name}
            <strong>{numberWithSpaces(price)} ₽</strong>
          </li>
        ))}
      </ul>

      <div className='presets-table'>
        <div className='head'>
          <div className='col'></div>
          {items.map(({ id, price, projectPreset }) => (
            <div key={id} className='col'>
              <div className='content'>
                <strong className='name'>{projectPreset.name}</strong>
                <strong className='price'>{numberWithSpaces(price)} ₽</strong>
              </div>
            </div>
          ))}
        </div>
        <div className='body'>
          {allFeatures.map(({ id, name }, featureIndex) => (
            <div key={id} className='row'>
              <div className='col'>
                <div className='content'>{name}</div>
              </div>
              {items.map(({ id, projectPreset }) => (
                <div key={id + featureIndex} className='col'>
                  <div className='content'>
                    {projectPreset.presetFeatures[featureIndex] ? (
                      <span className='circle' />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </StyledContainer>
  )
}

const FeaturePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
})

ProductPresets.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      price: PropTypes.number,
      projectPreset: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        presetFeatures: PropTypes.arrayOf(FeaturePropType),
      }),
    }),
  ),
  allFeatures: PropTypes.arrayOf(FeaturePropType),
}

export default memo(ProductPresets)
