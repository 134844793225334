import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledList,
  StyledListItem,
  StyledLink,
  StyledButton,
} from './styledComponents'

export const AsideLinks = ({ children }) => {
  return <StyledList>{children}</StyledList>
}

export const AsideLink = ({ children, onClick, isActive, hash, ...rest }) => {
  return (
    <StyledListItem {...rest}>
      {hash ? (
        <StyledLink href={`#${hash}`} onClick={onClick} isactive={+isActive}>
          {children}
        </StyledLink>
      ) : (
        <StyledButton type='button' onClick={onClick} isactive={+isActive}>
          {children}
        </StyledButton>
      )}
    </StyledListItem>
  )
}

AsideLink.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  hash: PropTypes.string,
}
